import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { IMarketplace } from '@interfaces/marketplace.interface';
import { ICountry } from '@interfaces/ICountry';
import { SessionHelper } from '@helpers/session.helper';
import { debounce } from 'lodash';
import { LOCALE_FR } from '@constants';
import {IGrcReason} from '@components/order/interfaces/order-refund.interface';
import {GrcReasonResource} from '@components/order/resources/grc-reason-resource.service';


@Component({
  selector: 'app-order-details',
  template: require('./order-details.component.html'),
  providers: [
    GrcReasonResource
  ],
})
export class OrderDetailsComponent extends AbstractComponent implements OnInit {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private grcReasonResource: GrcReasonResource,
  ) {
    super($translate, authService, resource, state);
  }

  public form: FormGroup;
  public currentCountry: ICountry;
  public marketplaces: IMarketplace[];
  public orderTypes: { label: string, value: string }[];
  public paymentTypes: { label: string, value: string }[];
  public showPaymentTypes: boolean = false;
  public showPaymentLink: boolean = false;
  public showEstimate: boolean = false;
  public showVatExempt: boolean = false;
  public estimate: boolean = false;
  public reasons: { label: string; id: string }[];
  public grcReasons:  { label: string; id: string }[];

  @Input() public parentForm: FormGroup;

  @Output() public onUpdateFields: EventEmitter<boolean> = new EventEmitter();

  private updateOrderParent = debounce((value: number) => {
    this.resource.get(value, { blocking: false, throwViolations: false })
      .subscribe((response) => {
        this.updateFields();
      }, (error) => {
        this.form.get('orderParentId').setErrors({ 'invalid': true });
      });
  }, 500);

  public ngOnInit(): void {
    this.currentCountry = SessionHelper.getCountry();
    this.marketplaces = SessionHelper.getMarketplaces()
      .sort((a: IMarketplace, b: IMarketplace) => {
        if (a.commercialName.toUpperCase() < b.commercialName.toUpperCase()) {
          return -1;
        }
        if (a.commercialName.toUpperCase() > b.commercialName.toUpperCase()) {
          return 1;
        }

        return 0;
      })
      .filter((marketplace: IMarketplace) => !['retail0000', 'retail0001'].includes(marketplace.code))
    ;
    this.orderTypes = SessionHelper.getOrderTypes()
      .map((orderType: string) => ({ label: this.translate(`DATA.ORDER_TYPES.${orderType.toUpperCase()}`), value: orderType, }));

    if ('FR' !== SessionHelper.getCountry().code) {
      this.orderTypes = this.orderTypes.filter((orderType: any) => ('repatriation' !== orderType.value && 'shooting' !== orderType.value));
    }

    this.paymentTypes = this.currentCountry.paymentTypes
      .map((paymentType: string) => ({ label: this.translate(`DATA.PAYMENT_TYPES.${paymentType.toUpperCase()}`), value: paymentType, }));

    this.buildForm();
    this.fillForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      marketplace: [null, Validators.required],
      orderType: [null, Validators.required],
      paymentType: [null, Validators.required],
      paymentLink: [null],
      vatExempt: [null],
      zendeskTicketId: [null],
      orderParentId: [null],
      reason: [null],
      grcReason: [null],
      estimate: [false]
    });
    if (this.state.params.type) {
      this.form.get('orderType').patchValue(this.state.params.type);
    }
    this.grcReasonResource.cGet({'exists[parent]': false, 'active': 1, 'reshipment': 1}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: IGrcReason[]) => {

        this.reasons = response.map((reason: IGrcReason) => {
          return {
            label: (reason.translations[SessionHelper.getUILanguage()] || reason.translations[LOCALE_FR]).reason,
            id: reason['@id'],
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });

        this.form.patchValue({ 'reason': null });
      })
    ;

    this.parentForm.addControl('details', this.form);
  }

  private fillForm(): void {
    if (this.state.params.marketplace) {
      const marketplace: IMarketplace = this.marketplaces.find((mkp: IMarketplace) => mkp.code === this.state.params.marketplace);
      this.form.get('marketplace').setValue(marketplace);
      this.updateMarketplace(marketplace);
    }

    if (this.state.params.orderType) {
      this.form.get('orderType').setValue(this.state.params.orderType);
    }

    if (this.state.params.paymentType) {
      this.form.get('paymentType').setValue(this.state.params.paymentType);
    }

    if (this.state.params.orderId) {
      this.form.get('orderParentId').setValue(this.state.params.orderId);
      this.updateOrderParent(this.state.params.orderId);
    }
  }

  private updateMarketplace(marketplace: any): void {
    if (!marketplace) {
      return;
    }

    this.state.params.marketplace = marketplace.code;
    this.showPaymentTypes = ['magento', 'website', 'showroom'].includes(marketplace.parent.code) || marketplace.code === 'b2b';
    this.form.get('paymentType').clearValidators();
    if (this.showPaymentTypes) {
      this.form.get('paymentType').setValidators(Validators.required);
    }
    this.form.get('paymentType').updateValueAndValidity();
    if (marketplace.code === 'showroom' || marketplace.code === 'alicedeals') {
      this.form.get('marketplace').setValue(marketplace);
      this.form.get('orderType').setValue('order');
    }
    this.updateFields();
  }

  private updateFields(): void {
    const marketplace: IMarketplace = this.form.get('marketplace').value;
    this.state.params.marketplace = marketplace.code;
    const parentMarketplace: string = marketplace ? marketplace.parent.code : null;
    const isOrderType: boolean = !!this.form.get('orderType').value;
    const isAGMarketplace: boolean = 'magento' === parentMarketplace || 'website' === parentMarketplace;

    this.showPaymentLink = (['website', 'showroom'].includes(marketplace.parent.code) || marketplace.code === 'b2b' ) &&
      this.form.get('paymentType').value === 'credit_card';

    this.showVatExempt = marketplace.code === 'b2b';

    this.orderTypes = SessionHelper.getOrderTypes()
      .map((orderType: string) => ({ label: this.translate(`DATA.ORDER_TYPES.${orderType.toUpperCase()}`), value: orderType, }));

    if (marketplace.code === 'b2b') {
      this.orderTypes.push({ label: this.translate(`DATA.ORDER_TYPES.ESTIMATE`), value: 'estimate', });
    }

    if (!this.showPaymentLink) {
      this.form.get('paymentLink').setValue(null);
    }

    if (!this.showVatExempt) {
      this.form.get('paymentLink').setValue(false);
    }

    marketplace || (isAGMarketplace && isOrderType)
      ? this.onUpdateFields.emit(true)
      : this.onUpdateFields.emit(false);
  }

  private updateOrderType(type: { [key: string]: string }): void {
    this.form.get('reason').setValidators(type.value === 'sav' ? [Validators.required] : null);
    this.form.get('grcReason').setValidators(type.value === 'sav' ? [Validators.required] : null);
    this.form.get('orderParentId').setErrors(null);
    this.form.get('orderParentId').setValidators(type.value === 'sav' ? [Validators.min(0), Validators.required] : null);
    this.form.get('orderParentId').updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  public OnSelectReason(event: any) {
    this.grcReasonResource.cGet({'parent': event.id.split('/').pop(), 'reshipment': 1}, { returnHydraMembers: true, blocking: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: IGrcReason[]) => {

        this.grcReasons = response.map((reason: IGrcReason) => {
          return {
            label: (reason.translations[SessionHelper.getUILanguage()] || reason.translations[LOCALE_FR]).reason,
            id: reason['@id'],
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });

        this.grcReasons  = this.grcReasons.filter(elm => elm);

        if (this.grcReasons.length === 0) {
          this.form.get('orderParentId').setErrors(null);
          this.form.controls['grcReason'].clearValidators();
          this.form.get('grcReason').updateValueAndValidity({ onlySelf: true, emitEvent: true });
        } else {
          this.form.patchValue({ 'grcReason': null });
          this.form.controls['grcReason'].setValidators(Validators.required);
          this.form.get('grcReason').updateValueAndValidity({ onlySelf: true, emitEvent: true });
        }
      })
    ;
  }
  public reloadOnMarketplaceChange() {
    this.state.go('.', this.state.params, { reload: true });
  }

}
