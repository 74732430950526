import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { OrderResource } from '@resources/order.resource';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderNewHelper } from '@helpers/OrderNewHelper';
import { ICountry } from '@interfaces/ICountry';
import { SessionHelper } from '@helpers/session.helper';
import { LogisticHelper } from '@helpers/LogisticHelper';
import { IArrival } from '@components/product/interfaces';
import { ProductResource } from '@components/product/product.resource';
import { takeUntil } from 'rxjs/operators';
import { IOrderItemOrderList } from '@components/order/interfaces/order-list.model.interface';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants/form';
import { IWarehouse } from '@components/export-logistics/forms';
import { ICarrierGroup } from '@interfaces/carrier-group.interface';
import { Subject } from 'rxjs/Subject';
import { CODE_ES, CODE_FR, CODE_GB, CODE_IT } from '@constants';
import { SnackbarService } from '@components/snackbar';
import { ServiceResource } from '@components/service/service.resource';

@Component({
  selector: 'app-order-product-list',
  template: require('./order-product-list.component.html'),
  styles: [require('./order-product-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: OrderResource },
  ],
})
export class OrderProductListComponent extends AbstractComponent implements OnInit {

  @Output() public onUpdate: EventEmitter<any> = new EventEmitter();

  @Input() public order: any = {};
  @Input() public parentForm: FormGroup;
  @Input() public carrierGroup: ICarrierGroup[];
  @Input() public items: any[] = [];

  public $drynRun = new Subject<any>();

  public form: FormGroup;
  public currentCountry: ICountry = SessionHelper.getCountry();
  public warehouses: IWarehouse[] = this.filterWarehouseIfShowroom(LogisticHelper.getAssignableWarehouses(SessionHelper.getWarehouses()));
  public arrivals: IArrival[] = SessionHelper.get('arrivals');
  public orderProductList: IOrderItemOrderList[] = [];
  public itemToPatch: any[] = [];
  public objectKeys: (o: {}) => any[] = Object.keys;
  public discountTypes = [{ value: 'currency', label: this.currentCountry.code === CODE_GB ? '£' : '€' }, { value: 'percentage', label: '%' }];
  public fidelityTypes = [{ value: 'currency', label: this.currentCountry.code === CODE_GB ? '£' : '€' }, { value: 'percentage', label: '%' }];
  public canAddSageDescription = false;
  public warehousesCurrent: any[] = SessionHelper.getWarehouses();
  public serviceInsurance: any = [];
  public serviceInsuranceAmount = 0;
  public insuranceActive = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private productResource: ProductResource,
    private snackbar: SnackbarService,
    private services: ServiceResource,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.buildForm();
    this.readArrivals();
    this.drynRunSubcriber();
    if (this.items) {
      this.items.forEach((item: any, i: number) => {
        this.addNewOrderProduct(item.product.quantity);
        const  formProductList = (<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[i]);
        formProductList.get('sku').patchValue(item.product.id);
      });
    }
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      orderProductList: this.formBuilder.array([]),
      changeReason: [null],
      insurance: [null],
    });

    this.parentForm.addControl('productList', this.form);
  }

  public addNewOrderProduct(quantity?: number): void {
    const item = {
      insurance: false,
      carrier: '',
      isNew: true,
      manuallyAdded: true,
      product: {
        sku: '',
        insuranceId: '',
        insuranceAmount: 0,
        insuranceCost: 0,
      },
      quantity: quantity || 1,
      shippingPrice: 0,
      discount: 0,
      discountPercent: 0,
      unitPrice: 0,
      unitPriceTmp: 0,
      totalPrice: 0,
      warehouse: '',
      discountType: 'currency',
      unpaired: false,
      packagesNumber: <number[]>[],
      sageDescription: '',
      secondHand: false,
      fidelity: 0,
      fidelityPercent: 0,
      fidelityType: 'currency',
    };

    this.orderProductList.push(item);

    const orderProductFormControl: FormGroup = this.formBuilder.group({
      warehouse: [item.warehouse ? item.warehouse : null, Validators.required],
      carrier: [item.carrier ? item.carrier : null, Validators.required],
      sku: [item.product.sku],
      quantity: [item.quantity, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(1)]],
      unitPrice: [item.unitPrice, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0)]],
      unitPriceTmp: [item.unitPrice, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0)]],
      discountType: [item.discountType, Validators.required],
      discount: [item.discount, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0)]],
      discountPercent: [item.discountPercent, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0), Validators.max(100)]],
      shippingPrice: [item.shippingPrice, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0)]],
      sageDescription: [item.sageDescription],
      unpaired: [item.unpaired],
      secondHand: [item.secondHand],
      insurance: [item.insurance],
      insuranceId: [item.product.insuranceId],
      insuranceCost: [item.product.insuranceCost],
      insuranceAmount: [item.product.insuranceAmount],
      fidelityType: [item.fidelityType, Validators.required],
      fidelity: [item.fidelity, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0)]],
      fidelityPercent: [item.fidelityPercent, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0), Validators.max(100)]],
    });

    this.serviceInsuranceAmount = 0;

    (<FormArray>this.form.get('orderProductList')).push(orderProductFormControl);
  }

  public readArrivals(): void {
    if (OrderNewHelper.isDateExpired('arrivals')) {
      this.productResource.filterArrivalsByCountryCodeV2(this.currentCountry.code, { dontUseModel: true })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          SessionHelper.set('arrivals', response);
          OrderNewHelper.setDateExpired('arrivals', Date.now());
          this.arrivals = response;
        });
    }

    this.arrivals = SessionHelper.get('arrivals');
  }

  public onChangeSku(item: IOrderItemOrderList, index: any) {
    const skuFormControl: AbstractControl = (<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index]).get('sku');
    if (skuFormControl.value && skuFormControl.value.id) {
      this.productResource.get(skuFormControl.value.id, { entryPoint: `/v2/products/${skuFormControl.value.id}`, dontUseModel: true })
        .subscribe(
          (productResponse: any) => {

            item.product = productResponse;

            item.product.id = item.product['@id'];
            item.product.masterProduct.id = item.product.masterProduct['@id'];
            item.product.superProduct.id = item.product.superProduct['@id'];
            item.product.country.id = item.product.country['@id'];

            delete item.product.manufacturer;
            delete item.unitPrice;

            if (!this.order.items) {
              this.order.items = [];
            }

            this.order.items[index] = item;

            const orderTmp = this.order;
            delete orderTmp.id;

            this.serviceInsuranceAmount = 0;


            this.fetchService();
            this.dryRun(orderTmp, index);
          }
        );
    }
  }

  public onChangeWarehouse(index: any) {
    const newWarehouse = <FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('warehouse').value;
    // @ts-ignore
    if (this.state.params.marketplace === 'showroom' && newWarehouse === 'ecospace') {
      this.order.items[index].carrier = 'enlevement';
    }
    const orderTmp = this.order;
    orderTmp.items[index].warehouse = newWarehouse;
    if (orderTmp.items[index].unpaired && !this.hasStockPackages(orderTmp.items[index])) {
      this.snackbar.warn(this.translate('PAGE.ORDER.NEW.ALERT.PACKAGE_NO_STOCK'));
      this.order.items[index].packagesNumber = [];
    }
    this.dryRun(orderTmp, index);
  }

  public onChangeCarrier(index: any) {
    const newCarrier = <FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('carrier').value;
    const orderTmp = this.order;
    orderTmp.items[index].carrier = newCarrier;
    orderTmp.items[index].canChangeCarrier = false;
  }

  public onChangeShippingPrice(index: any) {
    const newShippingPrice = +<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('shippingPrice').value;
    const orderTmp = this.order;
    orderTmp.items[index].shippingPrice = newShippingPrice;
    if (
      'enlevement' === this.order.items[index].carrier
      && 0. !== newShippingPrice
    ) {
      this.snackbar.warn(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.NO_SHIPING_ON_PICKUP'));
    }
    this.$drynRun.next({ order: orderTmp, index: index });
  }

  public onChangeDiscount(index: any) {
    let newDiscount = 0,
      newDiscountPercent = 0;
    const totalPrice = this.order.items[index].totalPrice;

    const discountType = <string><any><FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('discountType').value;
    const discountValue = +<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('discount').value;
    const discountPercentValue = +<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('discountPercent').value;
    if (discountType === 'percentage') {
      newDiscount = totalPrice ? Math.round(((discountPercentValue / 100) * totalPrice) * 100) / 100 : 0;
      newDiscountPercent = Number.isFinite(discountPercentValue) ? discountPercentValue : 0;
    } else {
      newDiscount = Number.isFinite(discountValue) ? discountValue : 0;
      newDiscountPercent = totalPrice ? Math.round((discountValue * 100) / totalPrice) : 0;
    }
    const orderTmp = this.order;
    orderTmp.items[index].discount = newDiscount;
    orderTmp.items[index].discountPercent = newDiscountPercent;
    orderTmp.items[index].discountType = discountType;

    this.$drynRun.next({ order: orderTmp, index: index });
  }

  public onChangeFidelity(index: any) {
    let newFidelity = 0,
      newFidelityPercent = 0;
    const totalPrice = this.order.items[index].totalPrice;

    const fidelityType = <string><any><FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('fidelityType').value;
    const fidelityValue = +<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('fidelity').value;
    const fidelityPercentValue = +<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('fidelityPercent').value;
    if (fidelityType === 'percentage') {
      newFidelity = totalPrice ? Math.round(((fidelityPercentValue / 100) * totalPrice) * 100) / 100 : 0;
      newFidelityPercent = Number.isFinite(fidelityPercentValue) ? fidelityPercentValue : 0;
    } else {
      newFidelity = Number.isFinite(fidelityValue) ? fidelityValue : 0;
      newFidelityPercent = totalPrice ? Math.round((fidelityValue * 100) / totalPrice) : 0;
    }
    const orderTmp = this.order;
    orderTmp.items[index].fidelity = newFidelity;
    orderTmp.items[index].fidelityPercent = newFidelityPercent;
    orderTmp.items[index].fidelityType = fidelityType;

    this.$drynRun.next({ order: orderTmp, index: index });
  }

  public onChangeUnitPrice(index: any) {
    const newUnitPrice = +<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index].get('unitPrice').value;
    const orderTmp = this.order;
    orderTmp.items[index].unitPrice = newUnitPrice;
    setTimeout(
      this.$drynRun.next({ order: orderTmp, index: index }),
      1000
    );
  }

  public onChangeQuantity(index: any) {
    const orderTmp = this.order;
    orderTmp.items[index].quantity = +(<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index]).get('quantity').value;
    this.dryRun(orderTmp, index);
  }

  public onClickRadio(index: any, value: string) {
    let orderTmp = this.order;
    switch (value) {
      case 'unpaired':
        orderTmp = this.onChangeUnpaired(index, true, orderTmp);
        orderTmp = this.onChangeSecondHand(index, false, orderTmp);
        break;
      case 'secondHand':
        orderTmp = this.onChangeUnpaired(index, false, orderTmp);
        orderTmp = this.onChangeSecondHand(index, true, orderTmp);
        break;
      default:
        orderTmp = this.onChangeUnpaired(index, false, orderTmp);
        orderTmp = this.onChangeSecondHand(index, false, orderTmp);
        break;
    }

    this.dryRun(orderTmp, index);
  }

  public onChangeUnpaired(index: any, active: boolean, orderTmp: any): any {
    orderTmp.items[index].unpaired = active;

    if (!orderTmp.items[index].unpaired) {
      orderTmp.items[index].packagesNumber = [];
    } else if (!this.hasStockPackages(orderTmp.items[index])) {
      this.snackbar.warn(this.translate('PAGE.ORDER.NEW.ALERT.PACKAGE_NO_STOCK'));
      orderTmp.items[index].packagesNumber = [];
    }

    return orderTmp;
  }

  public onChangeSecondHand(index: any, active: boolean, orderTmp: any): any {
    orderTmp.items[index].secondHand = active;

    if (orderTmp.items[index].secondHand) {
      switch (this.currentCountry.code) {
        case CODE_FR:
          orderTmp.items[index].warehouse = 'alice_deals';
          orderTmp.items[index].carrier = 'enlevement';
          break;
        case CODE_ES:
          orderTmp.items[index].warehouse = 'fercam';
          break;
        case CODE_IT:
          orderTmp.items[index].warehouse = 'fercamit';
          break;
      }
    }

    return orderTmp;
  }

  public updateInsurance(active: boolean, index: any) {
    const orderTmp = this.order;
    orderTmp.items[index].insurance = active;

    this.insuranceActive = active;

    for (let i = 0; i <= this.serviceInsurance.length; i++) {
      if (this.serviceInsurance[i] !== undefined) {
        orderTmp.items.forEach((el: any) => {
          if (active && el.totalPrice >= this.serviceInsurance[i].productMinPrice && el.totalPrice <= this.serviceInsurance[i].productMaxPrice) {
              this.serviceInsuranceAmount = this.serviceInsurance[i].amount;
              orderTmp.items[index].insurance = active;
              orderTmp.items[index].insuranceAmount = this.serviceInsuranceAmount;
              orderTmp.items[index].insuranceId = this.serviceInsurance[i].id;
              orderTmp.items[index].insuranceCost = this.serviceInsurance[i].cost;
            } else if (active === false) {
              orderTmp.items[index].insurance = active;
              orderTmp.items[index].insuranceAmount = 0;
              orderTmp.items[index].insuranceId = '';
              orderTmp.items[index].insuranceCost = 0;
            }
          });
        }
      }

    this.dryRun(orderTmp, index);
  }

  private fetchService() {
    this.services.getAllServices()
    .takeUntil(this.destroyed$)
    .subscribe((res: any) => {
      this.serviceInsurance = res;
    });
    return this.serviceInsurance;

  }

  public updatePackageNumber(nPackage: number, index: any) {
    const orderTmp = this.order;
    orderTmp.items[index].packagesNumber = [nPackage];

    this.dryRun(orderTmp, index);
  }

  public onChangeSageDescription(index: any) {
    const orderTmp = this.order;
    orderTmp.items[index].sageDescription = (<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index]).get('sageDescription').value;
  }

  public drynRunSubcriber() {
    this.$drynRun.debounceTime(800)
      .distinctUntilChanged().subscribe(
        dryRunSubjectValue => {
          this.dryRun(dryRunSubjectValue.order, dryRunSubjectValue.index);
        }
      );
  }

  public dryRun(orderTmp: any, index: any) {
    if (isNaN(orderTmp.items[index].quantity)) {
      return;
    }
    // tslint:disable-next-line: no-unused-expression
    <OrderResource>this.resource.dryRun(orderTmp).subscribe(
      (dryRunOrderResponse: any) => {
        this.order.items[index].quantity = dryRunOrderResponse.items[index].quantity;
        this.order.items[index].unitPrice = dryRunOrderResponse.items[index].unitPrice;
        this.order.items[index].totalPrice = dryRunOrderResponse.items[index].totalPrice;

        /* Part insurance start */
        if (this.insuranceActive) {
          this.order.items[index].unitPrice = dryRunOrderResponse.items[index].unitPrice;
          this.order.items[index].totalPrice = dryRunOrderResponse.items[index].totalPrice + this.serviceInsuranceAmount;
        } else if (this.insuranceActive === false && this.serviceInsurance !== 0) {
          this.order.items[index].unitPrice = dryRunOrderResponse.items[index].unitPrice;
          this.order.items[index].totalPrice = dryRunOrderResponse.items[index].totalPrice;
        }
        /* Part insurance end */

        this.order.items[index].shippingPrice = dryRunOrderResponse.items[index].shippingPrice;
        this.order.items[index].discountPercent = dryRunOrderResponse.items[index].totalPrice !== 0 ?
          Math.round((dryRunOrderResponse.items[index].discount * 100) / dryRunOrderResponse.items[index].totalPrice)
          : 0
          ;
        this.order.items[index].discount = dryRunOrderResponse.items[index].discount;
        this.order.items[index].warehouse = this.order.items[index].warehouse || dryRunOrderResponse.items[index].warehouse;
        this.order.items[index].carrier = this.order.items[index].carrier || dryRunOrderResponse.items[index].carrier;

        if (this.order.items[index].product.sku === 'DEALS') {
          if (this.order.items[index].unpaired) {
            this.order.items[index].unpaired = false;
            this.order.items[index].packagesNumber = [];
          }
          this.order.items[index].secondHand = true;

          switch (this.currentCountry.code) {
            case CODE_FR:
              this.order.items[index].warehouse = 'alice_deals';
              break;
            case CODE_ES:
              this.order.items[index].warehouse = 'fercam';
              break;
            case CODE_IT:
              this.order.items[index].warehouse = 'fercamit';
              break;
          }
        }

        if (this.order.items[index].secondHand && this.order.items[index].product.sku === 'DEALS') {
          this.canAddSageDescription = true;
        } else {
          this.order.items[index].sageDescription = null;
          this.canAddSageDescription = false;
        }

        if (this.state.params.marketplace === 'showroom' && this.order.items[index].product.sku === 'CMPDECO') {
          this.order.items[index].warehouse = 'ecospace';
          this.order.items[index].carrier = 'enlevement';
        }
        this.order.shippingPrice = dryRunOrderResponse.shippingPrice;
        this.order.discount = dryRunOrderResponse.discount;
        this.order.totalPrice = dryRunOrderResponse.totalPrice;
        (<FormGroup>(<FormArray>this.form.get('orderProductList')).controls[index]).patchValue(this.order.items[index]);

        this.onUpdate.emit(this.order);
      }
    );
  }

  public prepareNewOrderBody(): IOrderItemOrderList {
    return this.order;
  }

  public removeOrderProduct(index: number): void {
    this.orderProductList.splice(index, 1);
    (<FormArray>this.form.get('orderProductList')).controls.splice(index, 1);
    this.order.items.splice(index, 1);
    this.onUpdate.emit(this.order);
  }

  public goToProductOnWebsite(sku: string) {
    window.open(`https://www.alicesgarden.fr/sku/${sku}`, '_blank');
  }

  public disableAllForm() {
    this.form.disable();
  }

  public getStockOriginByWarehouse(warehouseCode: any) {
    return this.warehousesCurrent.find(e => e.code === warehouseCode).stockOrigin;
  }

  public getStockQuantityForPackage(item: any, packageNumber: number): number {
    const warehouse = item.warehouse;
    const productPackage = item.product && item.product.packages && item.product.packages[packageNumber] || null;
    if (!productPackage) {
      return 0;
    }
    const productPackageStock = productPackage.stocks && productPackage.stocks
      .filter((stock: any) => stock.type === 'SP' && stock.warehouse.code === warehouse)
      .at(0)
      ;

    return productPackageStock && productPackageStock.quantity || 0;
  }

  private getAllStockPackages(item: any) {
    const n = item.product.packages && item.product.packages.length || 0;
    return [...new Array(n).keys()].map((i: number) => this.getStockQuantityForPackage(item, i));
  }

  private hasStockPackages(item: any) {
    if (!['lsl', 'logs', 'bureauneauf'].includes(item.warehouse)) {
      return true;
    }

    return !this.getAllStockPackages(item).every((quantity: number) => quantity < 1);
  }

  private filterWarehouseIfShowroom(assignableWarehouses: any[]) {
    if (this.state.params.marketplace !== 'showroom') {
      return assignableWarehouses;
    }
    assignableWarehouses = assignableWarehouses.filter((warehouse: any) => warehouse.countries.map((country: any) => country.code).includes('FR'));
    return assignableWarehouses;
  }
}
