import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { ArrivalModel } from '@components/container/models/arrival.model';
import { ICommercialEntity } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { SupplierModel } from '@components/supplier/models/supplier.model';

export class ContainerModel {
  '@id': string;
   id: string;
   commercialEntity: ICommercialEntity;
   warehouse: IWarehouses;
   supplier?: SupplierModel;
   reference?: string;
   containerNumber: string;
   sageIdentifier?: string;
   date?: Date;
   comment?: string;
   freightPrice?: number;
   arrivalPrice?: number;
   additionalPrice?: number;
   sum: number;
   arrivalsFreightPrice: number;
   arrivals: ArrivalModel[];
   weekNumber?: number;
   freightForwarder: string;
   shippingCompany: string;
   freeTime: string;
   shipName: string;
   portOfLoading: string;
   portOfDischarge: string;
   estimatedTimeOfDeparture: Date;
   estimatedTimeOfArrival: Date;

  constructor(container: IHydraMember) {
    this['@id'] = container['@id'];
    this.id = container.id;
    this.commercialEntity = container.commercialEntity;
    this.warehouse = container.warehouse;
    this.supplier = container.supplier ? new SupplierModel(container.supplier) : null;
    this.reference = container.reference;
    this.containerNumber = container.containerNumber;
    this.sageIdentifier = container.sageIdentifier;
    this.date = container.date;
    this.comment = container.comment;
    this.freightPrice = container.freightPrice;
    this.arrivalPrice = container.arrivalPrice;
    this.additionalPrice = container.additionalPrice;
    this.sum = container.sum;
    this.arrivalsFreightPrice = container.arrivalsFreightPrice;
    this.arrivals = container.arrivals.map((arrival: IHydraMember) => new ArrivalModel(arrival));
    this.weekNumber = container.weekNumber;
    this.freightForwarder = container.freightForwarder;
    this.shippingCompany = container.shippingCompany;
    this.freeTime = container.freeTime;
    this.shipName = container.shipName;
    this.portOfLoading = container.portOfLoading;
    this.portOfDischarge = container.portOfDischarge;
    this.estimatedTimeOfDeparture = container.estimatedTimeOfDeparture;
    this.estimatedTimeOfArrival = container.estimatedTimeOfArrival;
  }
}
